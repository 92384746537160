import '@shared/style/root.scss'
import Vue from 'vue'
import App from '@/App.vue'
// import DirectLinkApp from '@/DirectLinkApp.vue'
import VueRouter from 'vue-router'
import router from '@/assets/js/router'
import VueBar from 'vuebar'
import VueFilter from 'vue-filter'
import directives from '@/assets/js/directives.js'
import filters from '@/assets/js/filters.js'
import VueDynamic from 'vue-dynamic'
import MultiLanguage from '@/assets/js/vue-multilanguage-edited.js'
import Languages from '@/assets/translations/main.js'
import Notify from 'vue-notification'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueResource from 'vue-resource'
import { EventBus, signOut, Users, getEnvironment } from '@/assets/js/helpers.js'
import { store } from '@/assets/js/store'
import { throttle } from 'lodash'
import VueKeepAliveDev from 'vue-keep-alive-dev'
import PortalVue from 'portal-vue'
import tooltip from '@/directives/tooltip.ts'

Vue.config.productionTip = false

Vue.use(VueBar)
Vue.use(VueFilter)
Vue.use(VueDynamic, { name: 'dynamic' })
Vue.use(MultiLanguage, Languages)
Vue.use(Notify)
Vue.use(VueKeepAliveDev)
Vue.use(PortalVue)
Vue.use(VueResource)

// list of deprecated apps, the key is the deprecated app name, the value is the new app name.
const deprecatedApps = {
  'lassousersettings': 'lassosettings'
}

// if we're developing on the direct link version of the portal, set below to true:
let isDirectLink = false

// if we're developing on the product version of the portal, set below to true:
// let isProductAccess = true

const environment = getEnvironment()

if (environment !== 'Development') {
  // sentry
  Sentry.init({
    dsn: 'https://e681300aa62545ba85a4809710d8ca7c@sentry.io/28414',
    ignoreErrors: [
      'Non-Error promise rejection captured'
    ],
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
    environment: getEnvironment()
  })
}

let writeKey

switch (getEnvironment()) {
  case 'Production':
  default:
    writeKey = '6XbNdqXLKOW3Yx6T'
    break
  case 'Development':
  case 'Staging':
  case 'Dev1':
  case 'Dev2':
    writeKey = 'tPhiPguupx8RzaYm'
    break
}

// #region june.so
let juneLoaded = false
window.analytics = { _writeKey: writeKey }
const juneScript = document.createElement("script")
juneScript.type = "application/javascript"

const junePromise = new Promise((resolve, reject) => {
  // do not track anything when we're impersonating.
  if (store.state.impersonating) resolve(false)
  juneScript.onload = () => resolve(true)
  // script.onerror = reject
  juneScript.onerror = () => {
    Sentry.captureException(new Error(`Could not load june script, unpck might be down.`))
    resolve(false)
  }
})

juneScript.src = "https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js"
const first = document.getElementsByTagName('script')[0]
first.parentNode.insertBefore(juneScript, first)

EventBus.$on('june:identifyUser', async ({ id, traits = {} }) => {
  if(!(await junePromise)) return
  traits.environment = getEnvironment()
  window.analytics.identify(id, traits)
})

EventBus.$on('june:identifyOrg', async ({ id, traits = {} }) => {
  if(!(await junePromise)) return
  traits.environment = getEnvironment()
  window.analytics.group(id, traits)
})

EventBus.$on('june:trackEvent', async ({ event, traits = {} }) => {
  if(!(await junePromise)) return
  setTimeout(() => {
    traits.environment = getEnvironment()
    traits.userBrowserId = store.state.userBrowserId
    window.analytics.track(event, traits)
  }, 10)
})

EventBus.$on('june:trackPage', async () => {
  if(!(await junePromise)) return
  setTimeout(() => {
    window.analytics.page()
  }, 10)
})
//#endregion

// setup directives
Object.keys(directives).forEach(function (key) {
  Vue.directive(key, directives[key])
})
Vue.directive('tooltip', tooltip)

/* setup filters */
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key])
})

store.dispatch('initialize').then(() => {
  // initialize Lasso product SDK
  if (isDirectLink || store.state.direct) window.lasso.initialize({ product: 'lasso-direct', version: '1.0.0' })
  // else if (isProductAccess || window.isProductAccess) window.lasso.initialize({ product: 'lasso-product', version: '1.0.0' })
  else window.lasso.initialize({ product: 'lasso', version: '1.0.0' })
  
  router.beforeEach(async (to, from, next) => {
    // if we're in "direct link" mode, then we have to make sure to persist the "direct" query param, so that we can refresh the page and still stay in direct mode.
    if (store.state.direct) {
      // do not go to route if direct link and not entity, app or home view
      if (to.name !== 'entity' && to.name !== 'app' && to.name !== 'search') next({ name: 'search', replace: true })
  
      // do not allow going to lassosettings in direct link mode
      if (to.params.appName == 'lassosettings') next({ name: 'search', replace: true })

      // make sure we can't go to the home screen without having the search bar visible.
      if (to.name === 'search' && to.query.search === undefined) {
        router.replace({ name: 'search', params: {}, query: { ...from.query, ...to.query, search: null } })
        return
      }
  
      // keep any query parameters across routes
      if (from.name && to.path !== from.path && JSON.stringify(from.query) !== JSON.stringify(to.query)) {
        let newTo = { name: to.name, params: to.params, query: JSON.parse(JSON.stringify(from.query)) }
        next(newTo)
        return
      }
    }
  
    if (store.state.lockedByModal) next(false)
    else {
      switch (to.name) {
        case 'list':
          await store.dispatch('getTags')
          let list = store.getters.tag(to.params.listId)
          if (list)  next()
          else next({ name: 'search', replace: true })
          return
        case 'app':
          if (to.params.appName in deprecatedApps) {
            next(`/app/${deprecatedApps[to.params.appName]}/${to.params.viewName || ''}`)
            return
          }
          var app = store.getters.app(to.params.appName)
          if (app || store.getters.appFromSolution(to.params.appName)) next()
          else next({ name: 'search', replace: true, query: to.query })
          return
        default: next()
      }
    }
  })

  // Initializes the router with the current location, including query parameters.
  // This is required because of the way the router is used now
  router.replace({
    path: location.pathname,
    hash: location.hash,
    query: Object.fromEntries(new URLSearchParams(location.search).entries()),
  }).catch(reason => {
    // Hiding router error. Reference: https://stackoverflow.com/a/65326844/6020382
    if (import.meta.env.DEV) console.debug(reason)
  })
})

let doThrottledActivityBroadcast = throttle(() => {
  store.dispatch('broadcastActivity')
}, 10000, { leading: true })

router.afterEach((to, from) => {
  if (to.name === 'entity') {
    store.dispatch('getEntity', { lassoId: to.params.lassoId }).then(entity => {
      // document.title = entity.name
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    }, () => {})
    if (to.params.appName) setTimeout(() => EventBus.$emit('june:trackPage'), 1000)
  } else {
    setTimeout(() => EventBus.$emit('june:trackPage'), 1000)
  }

  if (to.params.appName) {
    try {
      const appPrettyName = store.getters.app(to.params.appName)?.name
      if (!appPrettyName)
        Sentry.captureException(new Error(`Pretty name for app not found: ${to.params.appName}`))
      EventBus.$emit('june:trackEvent', {
        event: `Module Opened: ${appPrettyName}`,
        traits: { lassoId: to.params.lassoId },
      })
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  // broadcast activity to push server in order to prevent multiple sessions for the same user. Only do this if not read-only (e.g. direct link shared by many users) or trial.
  if (!store.getters.allowMultipleSessions) doThrottledActivityBroadcast()
})

Vue.use(VueRouter)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<FullApp/>',
  data: {
    loadingMessage: null
  },
  components: {
    FullApp: App,
    // DirectLinkApp,
    VueBar
  },
  beforeMount () {
    // hack to add the translate function to vuex.
    this.$store.translate = this.translate
  },
  mounted () {
    // we need to set inital tab document title
    EventBus.$on('token-changed', tokens => {
      if (tokens.newToken) { // still logged in, but token has changed.
        if (store.state.direct) return // TODO: We should probably also log the user out here, but the below currently fails as we have not loaded a user.
        Users.getCurrent().then(user => {
          if (store.state.user.id !== user.id) { // The user has also changed
            EventBus.$emit('modal', {
              modalType: 'confirm',
              confirmTitle: this.translate('sign-in-other-user'),
              confirmDescription: this.translate('sign-in-other-user-description'),
              confirmButtonText: this.translate('continue'),
              hideCancelButton: true,
              onConfirm: () => window.location.replace('/'),
              requireAction: true
            })
          }
        })
      } else if (!tokens.newToken) {
        EventBus.$emit('modal', {
          modalType: 'confirm',
          confirmTitle: this.translate('signed-out'),
          confirmDescription: this.translate('signed-out-description'),
          confirmButtonText: this.translate('log-in-again'),
          hideCancelButton: true,
          onConfirm: () => signOut(),
          requireAction: true
        })
      }
    })
    window.addEventListener('blur', event => EventBus.$emit('app-click', event))
  }
})
